section.single-column {
  .container{
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    grid-gap: $gutter;
  }
  .copy{
    grid-column: 2 / span 1;
    h3{
      font-family: $font-secondary;
      font-weight: 700;
      @include fluid-type(font-size, 300px, 1600px, 30px, 38px);
      line-height: 1;
      padding-bottom: $spacer-xs;
      border-bottom: 2px solid $white;
      margin: 0 0 $spacer-xs 0;
    }
    .locations{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: $spacer-sm;
      .location-card{
        padding: $spacer-sm;
        background-color: $grey;
      }
    }
  }
  .contact-form{
    grid-column: 2 / span 1;
    h3{
      margin-bottom: $spacer-sm;
    }
  }
}




@media screen and (max-width: 834px) {
  section.single-column {
    // margin-top: 2rem;
    .container{
      grid-template-columns: 1fr;
      grid-gap: 3rem;
    }
    .copy{
      grid-column: 1 / span 1;
      .locations{
        grid-template-columns: 1fr;
      }
    }
    .contact-form{
      grid-column: 1 / span 1;
    }
  }
}
