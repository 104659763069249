section.talent-detail {
  position: relative;
  margin-top: 6rem;
  .container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
    "images copy";
    grid-gap: $spacer-lg;
  }
  .talent-carousel{
    grid-area: images;
  }
  .carousel-cell{
    width: 100%;
    padding-bottom: 130%;
    height: 0;
    img{
      display: block;
      width: 100%;
      height: auto;
    }
  }
  .copy{
    grid-area: copy;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h4{
      margin-top: $spacer-md;
      margin-bottom: $spacer-sm;
    }
    ul{
      margin: 0 0 $spacer-md 0;
      padding: 0;
      list-style: none;
      column-count: 2;
      column-gap: 1rem;
      li{
        padding: $spacer-xs 0;
        border-bottom: 1px solid $grey;
        font-family: $font-secondary;
        font-weight: 700;
        @include fluid-type(font-size, 300px, 1600px, 18px, 20px);
        letter-spacing: -.01rem;
      }
    }
  }
  .directional{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .button{
      margin-right: $spacer-xs;
    }
  }
}




// responsive
@media screen and (max-width: 1024px) {
  section.talent-detail {
    .container{
      grid-template-columns: 1fr;
      grid-template-areas:
      "images"
      "copy";
      grid-gap: 3rem;
    }
  }
}
@media screen and (max-width: 834px) {
  section.talent-detail {
    margin-top: 2rem;
  }
}
