section.testimonial-blocks {
  position: relative;
  .container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: $spacer-lg;
    padding: $gutter;
  }
  .card{
    border-bottom: 3px solid $grey;
    padding-bottom: $spacer-xs;
    .text{
      h3{
        margin-bottom: 0;
        border-bottom: 0;
        padding: 0;
      }
    }
  }
}




// responsive
@media screen and (max-width: 768px) {
  section.testimonial-blocks {
    .container{
      grid-template-columns: 1fr;
    }
  }
}
