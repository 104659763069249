a.button {
  position: relative;
  align-self: flex-start;
  font-family: $font-secondary;
  font-size: 1.25rem;
  font-weight: 700;
  letter-spacing: -.01rem;
  text-decoration: none;
  color: $white;
  background-color: $accent-color;
  margin: 0 0 $spacer-xs 0;
  padding: .5rem 1rem;
  border-radius: 4px;
  box-shadow: 0px 3px 5px -2px rgba(0, 0, 0, .2);
  transition: all 0.3s ease;
  z-index: 10;
  &.prev{
    &::before {
      content: "\f060";
      position: relative;
      // top: -2px;
      margin-right: $spacer-xxs;
      font-family: "Font Awesome 5 Pro";
      font-weight: 100;
      font-size: 1.25rem;
      color: $white;
      transition: all 0.3s ease;
    }
    &::after {
      display: none !important;
    }
  }
  &::after {
    content: "\f061";
    position: relative;
    // top: -2px;
    margin-left: $spacer-xxs;
    font-family: "Font Awesome 5 Pro";
    font-weight: 100;
    font-size: 1.25rem;
    color: $white;
    transition: all 0.3s ease;
  }
  &.gen{
    &::after {
      display: none;
    }
  }
  &:hover{
    color: $white;
    background-color: darken($accent-color, 10%);
    box-shadow: 0px 1px 3px -1px rgba(0, 0, 0, .5);
    &::after {
      color: $white;
    }
  }
  &.hide{
    display: none !important;
  }
}
