*, *::after, *::before {
  box-sizing: border-box;
}

// HAMBURGER
#nav-span-wrapper{
  display: none;
}
header{
  background-color: $black;
}
// NAVBAR
.nav-container {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 20;
  height: 12rem;
  width: 100%;
  padding: 0 $gutter;
  background-color: $white;
  transition: transform $animation-duration $animation-timing;
}
.logo-mark-mobile{
  display: none;
}
.logo-mark{
  display: flex;
  align-items: center;
  img{
    display: block;
    width: 260px;
    height: auto;
  }
}
.nav{
  display: flex;
  align-items: center;
  list-style: none;
  margin-left: -2rem;
  margin-right: -2rem;
  li{
    display: inline-flex;
    margin: 0 2rem;
    a{
      font-weight: 800;
      font-size: .85rem;
      letter-spacing: .2rem;
      text-transform: uppercase;
      color: $black;
      padding: 0 0 2px 0;
      margin: 0;
      border-bottom: 1px solid rgba($primary-color, 0);
      transition: all .3s ease;
      &.active{
        color: $primary-color;
        border-bottom: 1px solid rgba($primary-color, 1);
      }
      &:hover{
        color: $primary-color;
        border-color: rgba($primary-color, 1);
      }
    }
  }
}






@media screen and (max-width: 834px) {
  .nav-open main {
    transform: translateX(-80%);
  }
  // HAMBURGER
  #nav-span-wrapper{
    position: absolute;
    top: 2rem;
    right: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    width: 30px;
    z-index: 30;
    transition: all .3s ease;
  }
  .nav-open #nav-span-wrapper{
    background-color: rgba($white, 0) !important;
  }
  .after-scroll{
    background-color: rgba($white, 1) !important;
  }
  .menu-title{
    display: none;
  }
  .nav-open .menu-title{
    color: transparent;
  }
  .nav-trigger {
    position: relative;
    height: 28px;
    width: 30px;
    overflow: hidden;
    color: transparent !important;
    white-space: nowrap;
    text-indent: 100%;
  }
  .nav-trigger span,
  .nav-trigger span::before,
  .nav-trigger span::after {
    position: absolute;
    height: 2px;
    width: 26px;
    background: $black;
  }
  .nav-trigger span {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease;
  }
  .nav-trigger span::before,
  .nav-trigger span::after {
    content: '';
    top: 0;
    left: 0;
    transition: all 0.3s ease, transform .3s;
  }
  .nav-trigger span::before {
    transform: translateY(-8px);
  }
  .nav-trigger span::after {
    transform: translateY(8px);
  }
  .nav-trigger:hover span,
  .nav-trigger:hover span::before,
  .nav-trigger:hover span::after {
    opacity: .9;
  }
  .nav-open .nav-trigger span {
    background: transparent;
  }
  .nav-open .nav-trigger span::before,
  .nav-open .nav-trigger span::after {
    background: $black;
  }
  .nav-open .nav-trigger span::before {
    transform: rotate(-45deg);
  }
  .nav-open .nav-trigger span::after {
    transform: rotate(45deg);
  }

  // OVERLAY
  .overlay {
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    background-color: $black;
    transition: all .3s ease;
    pointer-events: none;
  }
  .nav-open .overlay {
    opacity: .7;
  }

  // DRAWER
  .nav-container {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    z-index: 20;
    top: 0;
    right: 0;
    height: 100%;
    width: 80%;
    padding: 16vh 0 0 $spacer-xl;
    background-color: $white;
    overflow: auto;
    transform: translateZ(0);
    transform: translateX(168%);
    transition: transform .3s ease;
    .logo-mark{
      display: none;
    }
  }
  .nav-open .nav-container {
    transform: translateX(0%);
  }

  // MAIN NAV
  .nav{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    list-style: none;
    margin-left: 0;
    margin-right: 0;
    li{
      display: block;
      margin: $spacer-xs 0;
      line-height: 1.2;
      a{
        @include fluid-type(font-size, 300px, 1600px, 24px, 36px);
        font-weight: 500;
        margin: 0;
        transition: all .3s ease;
        &:hover{
          color: $accent-color;
        }
      }
    }
  }
  .logo-mark-mobile{
    display: block;
    margin-bottom: $spacer-md;
    img{
      display: block;
      width: 200px;
      height: auto;
    }
  }
}
