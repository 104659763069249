section.cards {
  position: relative;
  .container{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: $spacer-sm;
    padding: $gutter;
  }
  &.large{
    .container{
      grid-template-columns: repeat(2, 1fr);
    }
  }
  a{
    &.card{
      position: relative;
      padding-bottom: 100%;
      height: 0;
      overflow: hidden;
      .text{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        color: $white;
        padding: $spacer-md;
        pointer-events: none;
        z-index: 4;
        .view-more{
          @include fluid-type(font-size, 300px, 1600px, 11px, 12px);
          font-weight: 400;
          letter-spacing: .04rem;
          line-height: 1;
          text-transform: uppercase;
          transform: translateY(100%);
          opacity: 0;
          transition: all .4s ease;
        }
      }
      &:hover{
        .text{
          .view-more{
            transform: translateY(0);
            opacity: 1;
          }
        }
      }
      .card-bg{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center 33%;
        transform: scale(100%);
        transition: all .6s ease;
        &::after{
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background-image: linear-gradient(0deg, rgba(#000000, .8) 0%, rgba(#000000, 0) 70%);
          z-index: 1;
        }
        &.primary-bg{
          color: $white;
          background-color: $primary-color;
          transition: all .6s ease;
          &::after{
            background-image: none;
          }
          &:hover{
            background-color: #1d1d1d;
          }
        }
        &.black-bg{
          color: $white;
          background-color: #1d1d1d;
          &::after{
            background-image: none;
          }
        }
        &.color-overlay{
          &::after{
            background-image: linear-gradient(0deg, rgba(#000000, .9) 0%, rgba(#000000, 0) 100%);
          }
        }
        &:hover{
          transform: scale(110%);
        }
      }

      &.double{
        grid-column: auto / span 2;
        grid-row: auto / span 2;
      }
      &.wide{
        grid-column: auto / span 2;
        padding-bottom: calc(50% - .375rem);
      }
    }
  }
}




// responsive
@media screen and (max-width: 1024px) {
  section.cards {
    .container{
      grid-template-columns: repeat(3, 1fr);
    }
    a{
      &.card{
        &.double{
          grid-column: auto / span 1;
          grid-row: auto / span 1;
        }
        &.wide{
          grid-column: auto / span 1;
          padding-bottom: 100%;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  section.cards {
    .container{
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
@media screen and (max-width: 567px) {
  section.cards {
    .container{
      grid-template-columns: 1fr;
    }
    &.large{
      .container{
        grid-template-columns: 1fr;
      }
    }
  }
}
