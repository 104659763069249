ul{
  margin: 0;
  padding: 0;
  // BULLET SINGLE
  &.bullet-list-single{
    list-style: disc;
    margin: $spacer-xs 0 $spacer-sm 1rem;
    padding: 0;
    li{
      padding: 0 0 $spacer-xs 0;
      line-height: 1.2;
    }
  }
  // FOOTER LIST
  &.contact-info-footer{
    list-style: none;
    margin: 0 0 $spacer-md 0;
    padding: 0;
    li{
      padding: $spacer-xxs 0;
      border-top: 2px solid $primary-color;
      &.sub{
        @include fluid-type(font-size, 300px, 1600px, 16px, 18px);
        font-weight: 800;
        border-top: 0;
      }
      &:last-child{
        border-bottom: 2px solid $primary-color;
      }
      a{
        @include fluid-type(font-size, 300px, 1600px, 11px, 12px);
        line-height: 1.1;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: .04rem;
        color: $white;
        transition: all 0.3s ease;
        &:hover{
          color: $accent-color;
        }
      }
    }
  }
  &.main-nav-footer{
    list-style: none;
    margin: 0;
    padding: 0;
    li{
      padding: $spacer-xxs 0;
      a{
        @include fluid-type(font-size, 300px, 1600px, 11px, 12px);
        line-height: 1.1;
        text-transform: uppercase;
        font-weight: 900;
        letter-spacing: .04rem;
        color: $white;
        transition: all 0.3s ease;
        &:hover{
          color: $accent-color;
        }
      }
    }
  }
  &.social-footer{
    list-style: none;
    margin: 0;
    padding: 0;
    li{
      display: inline-block;
      padding: 0 $spacer-xxs;
      a{
        display: block;
        width: 100%;
        @include fluid-type(font-size, 300px, 1600px, 24px, 48px);
        text-align: center;
        padding-bottom: 100%;
        height: 0;
        color: $primary-color;
        transition: all 0.3s ease;
        &:hover{
          color: $white;
        }
      }
    }
  }
  // LOCATIONS
  &.location{
    list-style: none;
    margin: $spacer-xs 0 $spacer-sm 1rem;
    padding: 0;
    li{
      padding: 0;
      line-height: 1.2;
      @include fluid-type(font-size, 300px, 1600px, 14px, 16px);
    }
  }

}
