section.event-detail {
  position: relative;
  margin-top: 6rem;
  .container{
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    grid-gap: $gutter;
  }
  .request-form{
    grid-column: 2 / span 1;
    h3{
      margin-bottom: 2rem;
    }
  }
  .copy{
    grid-column: 2 / span 1;
  }
  .doc-link{
    padding: $spacer-sm 0 0 0;
  }
  .gallery{
    grid-column: 1 / span 3;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(100%, 300px), 1fr));
    grid-gap: $spacer-sm;
    .gallery-image{
      img{
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }
}





@media screen and (max-width: 834px) {
  section.event-detail {
    margin-top: 2rem;
    .container{
      grid-template-columns: 1fr;
      grid-gap: 3rem;
    }
    .request-form{
      grid-column: 1 / span 1;
    }
    .copy{
      grid-column: 1 / span 1;
    }
    .gallery{
      grid-column: 1 / span 1;
    }
  }
}
