
p{
  font-family: $font-primary;
  font-weight: 300;
  color: currentColor;
  margin: 0 0 $spacer-sm 0;
  @include fluid-type(font-size, 300px, 1600px, 16px, 20px);
  letter-spacing: -.02rem;
  line-height: 1.3;
  &.xlarge{
    @include fluid-type(font-size, 300px, 1600px, 26px, 48px);
    font-weight: 100;
    letter-spacing: -.02rem;
    line-height: 1.4;
  }
  &.large{
    @include fluid-type(font-size, 300px, 1600px, 24px, 36px);
    font-family: $font-secondary;
    font-weight: 300;
    letter-spacing: .01rem;
    line-height: 1;
  }
  &.small{
    @include fluid-type(font-size, 300px, 1600px, 13px, 14px);
  }
}
h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  margin: 0;
}
.sub-xl{
  font-family: $font-secondary;
  font-weight: 300;
  @include fluid-type(font-size, 300px, 1600px, 36px, 48px);
  letter-spacing: -.03rem;
  line-height: .9;
  margin: 0 0 $spacer-xs -2px;
  opacity: .4;
}
.sub-lg{
  font-family: $font-secondary;
  font-weight: 700;
  @include fluid-type(font-size, 300px, 1600px, 36px, 58px);
  letter-spacing: -.02rem;
  line-height: .9;
  padding-bottom: $spacer-xs;
  border-bottom: 2px solid $white;
  margin: 0 0 $spacer-xxs 0;
}
.sub-md{
  font-family: $font-secondary;
  font-weight: 700;
  @include fluid-type(font-size, 300px, 1600px, 30px, 38px);
  line-height: 1;
  padding-bottom: $spacer-xs;
  border-bottom: 2px solid $white;
  margin: 0 0 $spacer-xs 0;
}
.sub-sm{
  font-family: $font-secondary;
  font-weight: 700;
  @include fluid-type(font-size, 300px, 1600px, 24px, 28px);
  line-height: 1;
  // text-transform: uppercase;
  letter-spacing: .01rem;
  margin: 0;
}
.sub-xs{
  font-weight: 400;
  @include fluid-type(font-size, 300px, 1600px, 13px, 14px);
  line-height: 1.1;
  letter-spacing: -.01rem;
  margin: 0 0 $spacer-sm 0;
}
.sub-xxs{
  font-weight: 600;
  @include fluid-type(font-size, 300px, 1600px, 12px, 13px);
  letter-spacing: -.01rem;
  margin: 0;
}
.sub-desc{
  font-family: $font-secondary;
  font-weight: 300;
  @include fluid-type(font-size, 300px, 1600px, 22px, 26px);
  line-height: 1;
  letter-spacing: .01rem;
  border-bottom: 1px solid $grey;
  padding-bottom: $spacer-xxs;
  margin: 0 0 $spacer-md 0;
}
.title{
  font-weight: 700;
  @include fluid-type(font-size, 300px, 1600px, 16px, 20px);
  letter-spacing: -.02rem;
  margin: 0;
}
.description{
  font-weight: 400;
  @include fluid-type(font-size, 300px, 1600px, 13px, 14px);
  letter-spacing: -.01rem;
  margin: 0;
}
.date{
  font-weight: 500;
  @include fluid-type(font-size, 300px, 1600px, 17px, 18px);
  letter-spacing: -.02rem;
  margin: 0;
}
.time{
  font-weight: 500;
  @include fluid-type(font-size, 300px, 1600px, 17px, 18px);
  letter-spacing: -.02rem;
  margin: 0;
}
.card-label{
  font-weight: 600;
  @include fluid-type(font-size, 300px, 1600px, 12px, 13px);
  letter-spacing: -.01rem;
  margin: 0 0 $spacer-sm 0;
  color: $accent-color;
}

blockquote{
  position: relative;
  margin: 0;
  padding: 0;
  p{
    position: relative;
    font-family: $font-primary;
    font-weight: 400;
    @include fluid-type(font-size, 300px, 1600px, 16px, 18px);
    line-height: 1.3;
    letter-spacing: -.02rem;
    margin: 0 0 $spacer-xs 0;
  }
  &::before{
    position: absolute;
    top: -1.5rem;
    left: -.5rem;
    content: "\f10d";
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    @include fluid-type(font-size, 300px, 1600px, 40px, 60px);
    opacity: .1;
  }
}

.light{
  font-weight: 400;
}
