
.form-wrapper {
  margin: $spacer-md 0 0 0;
}

.form-control {
  display: block;
  border: 0;
  width: 100%;
  border-radius: 0;
  &::-webkit-input-placeholder {
    color: $black;
    letter-spacing: -.02rem;
  }
  &::-moz-placeholder {
    color: $black;
    letter-spacing: -.02rem;
  }
  &:-ms-input-placeholder {
    color: $black;
    letter-spacing: -.02rem;
  }
  &:-moz-placeholder {
    color: $black;
    letter-spacing: -.02rem;
  }
  &:focus {
    border-color: $accent-color !important;
    outline: none;
  }
}
label{
  @include fluid-type(font-size, 300px, 1600px, 12px, 13px);
  font-weight: 600;
  letter-spacing: -.02rem;
  color: $black;
  opacity: .8;
}
fieldset{
  margin: 0;
  padding: 0;
  border: 0;
}
.form-grid{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: $spacer-sm;
}
.form-list{
  margin: 0;
  padding: 0;
}
input{
  box-sizing: border-box;
  font-size: 1rem;
  width: 100%;
  height: 2.5rem;
  padding: $spacer-sm;
  border: 0;
  margin-top: 5px;
  background-color: $grey;
}
textarea {
  height: 10vh;
  padding: $spacer-sm;
  box-sizing: border-box;
  font-size: 1.15rem;
  width: 100%;
  margin-top: 5px;
  background-color: $grey;
  border: 0;
}
.form-column{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

input[type="file"] {
    display: none;
}
button{
  border: 0;
}
.btn-primary-form {
  @include fluid-type(font-size, 300px, 1600px, 15px, 16px);
  font-weight: 700;
  letter-spacing: .09rem;
  text-transform: uppercase;
  padding: $spacer-sm $spacer-md;
  margin: $spacer-md 0 0 0;
  color: $white;
  background-color: $accent-color;
  transition: all 0.3s ease;
  cursor: pointer;
  i{
    margin-left: $spacer-xs;
  }
  &:hover{
    color: $white;
    background-color: darken($accent-color, 5%);
    transition: all 0.3s ease;
  }
}


.foo { display: none; }
.captcha{
  grid-column: 1/-1 !important;
}

input[type="checkbox"] {
  position: relative;
  top: 1px;
  width: 1rem;
  height: 1rem;
  margin: 0 .25rem 0 0;
  background-color: $grey;
  border: 0;
  border-radius: 0;
}

// responsive
@media screen and (max-width: 768px) {
  .form-grid{
    grid-template-columns: 1fr;
  }
}
