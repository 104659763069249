.card-post{
  position: relative;
  border-radius: 10px;
  background-color: rgba($white, .5);
  box-shadow: 0px 2px 10px -3px rgba(0, 0, 0, .1);
  padding: $spacer-md;
  margin: 0;
  // figure{
  //   margin-bottom: $spacer-sm;
  // }
  p{
    line-height: 1.4;
  }
  .button{
    margin-top: $spacer-sm;
  }
}

.card-testimonial{
  position: relative;
  border-radius: 10px;
  background-color: rgba($white, .5);
  box-shadow: 0px 2px 10px -3px rgba(0, 0, 0, .1);
  padding: $spacer-lg;
  .credits{
    margin: 2rem 0 0 0;
  }
  &::before {
    content: "\f10d";
    position: absolute;
    top: -3px;
    left: 10px;
    font-family: "Font Awesome 5 Pro";
    font-size: 3rem;
    font-weight: 900;
    color: $tertiary-color;
  }
}

.card-event{
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr 2fr;
  grid-gap: $spacer-lg;
  padding: $gutter;
  border-radius: 10px;
  color: $black;
  background-color: rgba($white, .5);
  box-shadow: 0px 4px 10px -3px rgba(0, 0, 0, .1);
  i{
    color: $accent-color;
  }
  p{
    line-height: 1.5;
  }
}





// responsive
@media screen and (max-width: 576px) {
  .card-event{
    grid-template-columns: auto 1fr;
  }
}
