section.landing-hero {
    position: relative;
    margin-top: 6rem;
    .container{
        display: grid;
        grid-template-columns: 1fr 4fr 1fr;
        grid-gap: $gutter;
    }
    .image{
        grid-column: 1 / span 3;
        img{
            width: 100%;
            height: auto;

        }
    }
    .text{
        grid-column: 2 / span 1;
        h2{
            font-family: $font-secondary;
            font-weight: 700;
            @include fluid-type(font-size, 300px, 1600px, 36px, 58px);
            letter-spacing: -.02rem;
            line-height: .9;
            padding-bottom: $spacer-xs;
            border-bottom: 2px solid $white;
            margin: 0 0 $spacer-xxs 0;
          }
    }
}


@media screen and (max-width: 834px) {
    section.landing-hero {
      .container{
        grid-template-columns: 1fr;
        grid-gap: 3rem;
      }
      .text{
        grid-column: 1 / span 1;
        .locations{
          grid-template-columns: 1fr;
        }
      }
      .image{
        grid-column: 1 / span 1;
      }
    }
  }