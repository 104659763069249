section.page-id {
  .container{
    padding: 0 $gutter;
  }
  .copy{
  }
}




// responsive
// @media screen and (max-width: 834px) {
//
// }
