.sr-only{
  @include sr-only;
}

.container{
  position: relative;
  padding: $gutter;
}

// GRID SPAN
.span-all{
  grid-column: 1/-1 !important;
}
.span-2{
  grid-column: auto / span 2;
}
.tall{
  grid-row: auto / span 2;
}


// PADDING
.pd-0{
  padding: 0 !important;
}
.pd-t-0{
  padding-top: 0 !important;
}
.pd-t-sm{
  padding-top: $spacer-sm !important;
}
.pd-b-0{
  padding-bottom: 0 !important;
}
.pd-b-md{
  padding-bottom: $spacer-md !important;
}

.nav-margin{
  margin-top: 16vh;
}





// COLOR


.secondary-bg{
  color: $white;
  background-color: $secondary-color;
}
.tertiary-bg{
  background-color: $tertiary-color;
}
.accent-bg{
  color: $white;
  background-color: $accent-color;
}
.neutral-bg{
  background-color: $neutral-color;
}
.pattern{
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23804753' fill-opacity='0.5' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
}





// responsive
@media screen and (max-width: 768px) {
  .span-3{
    grid-column: 1/-1 !important;
  }
  .span-2{
    grid-column: 1/-1 !important;
  }
  .span-1{
    grid-column: 1/-1 !important;
  }
  .m-subpage{
    margin-top: 25vh;
  }
  .nav-margin{
    margin-top: 6vh;
  }
}
