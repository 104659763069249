section.staff-blocks {
  position: relative;
  .container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: $spacer-sm;
    padding: $gutter;
  }
  .card{
    position: relative;
    overflow: hidden;
    .image{
      position: relative;
      padding-bottom: 100%;
      height: 0;
      img{
        width: 100%;
        height: auto;
      }
    }
    .text{
      position: relative;
      width: 100%;
      color: $black;
      padding: $spacer-sm 0;
      z-index: 4;
      h3{
        margin-bottom: 0;
        border-bottom: 0;
        padding: 0;
      }
    }
  }
}




// responsive
@media screen and (max-width: 768px) {
  section.staff-blocks {
    .container{
      grid-template-columns: 1fr;
    }
  }
}
@media screen and (max-width: 567px) {

}
