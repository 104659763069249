@import "variables";
@import "mixins";
@import "navigation";
@import "footer";
@import "typography";
@import "helpers";


body {
  font-size: 16px;
  line-height: 1.4;
  font-family: $font-primary;
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  color: $black;
  background-color: $white;
  margin: 0;
  padding: 0;
}

main{
  position: relative;
  z-index: 1;
  height: 100vh;
  transition: transform .4s ease;
}
.brand-logo-mark{
  position: relative;
  padding: 2.2rem 0 0 2rem;
  img{
    display: block;
    width: 190px;
    height: auto;
  }
}
