section.footer {
  color: $white;
  background-color: $black;
  padding-bottom: $page-margin;
  margin-top: $spacer-lg;
  .container{
    position: relative;
    display: grid;
    grid-template-columns: auto 2fr 1fr 3fr 1fr;
    grid-template-areas:
    "logo contact nav social bbb";
    grid-gap: $gutter;
    z-index: 4;
  }
  .brand{
    grid-area: logo;
    img{
      display: block;
      width: 60px;
      height: auto;
    }
  }
  .contact{
    grid-area: contact;
  }
  .footer-nav{
    grid-area: nav;
  }
  .social{
    grid-area: social;
  }
  .accreditation{
    grid-area: bbb;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    img{
      display: block;
      width: 50px;
      height: auto;
    }
  }
}


// responsive
@media screen and (max-width: 768px) {
  section.footer {
    .container{
      grid-template-columns: auto 2fr 1fr 1fr;
      grid-template-areas:
      "logo contact nav bbb"
      "logo social social social";
    }
    .accreditation{
      img{
        width: 30px;
      }
    }
  }
}
@media screen and (max-width: 567px) {
  section.footer {
    .container{
      grid-template-columns: auto 2fr 1fr;
      grid-template-areas:
      "logo contact bbb"
      "logo nav nav"
      "logo social social";
    }
  }
}
